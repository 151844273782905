import services from "./index";
import qs from "querystring";

export default {
    get(id) {
        return services.http.get("/v1.0/account/" + id);
    },
    linkSlack(code, scope) {
        return services.http.post(`/v1.0/oauth/link-slack?code=${code}&scope=${scope}`, {});
    },
    linkTeams(code, scope) {
        return services.http.post(`/v1.0/oauth/link-teams?code=${code}&scope=${scope}`, {});
    },
    createToken() {
        return services.http.post(`/v1.0/oauth/create-token`, {});
    },
}